<template>
  <b-row class="d-flex justify-content-center">
    <b-col cols="12" md="9">
      <!-- #region Bilgilendirme -->
      <b-row class="d-flex justify-content-center">
        <b-col cols="12">
          <b-card bg-variant="primary" text-variant="white" class="w-100">
            <b-card-header class="justify-content-center">
              <h1 class="text-white">Bilgilendirme!</h1>
            </b-card-header>
            <b-card-body>
              <p class="mb-0">
                Değerli İş Ortağımız, Kredi kartı ile yapılan ödemeler için aşağıdaki tabloda banka isimleri ve taksit bilgileri belirtilmiştir.
                <br />
                Taksit sayıları bankaların sunduğu güncel kampanyalar dahilinde değişiklik gösterebilir.
                <br />
                <br />
                Saygılarımızla,
              </p>
              <h4 class="font-weight-bold text-white">Yaparlar İstif Makineleri A.Ş.</h4>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- #endregion -->
      <payment-banks />
      <!-- #region Ödeme -->
      <b-row>
        <b-col cols="12">
          <card-form />
        </b-col>
      </b-row>
      <!-- #endregion -->
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BImg, BCard, BCardHeader, BCardBody, BBadge } from 'bootstrap-vue'
import CardForm from './Card/CardForm.vue'
import PaymentBanks from './Banks.vue'
import { SET_DEFAULT_PAYMENT_DATA } from '@/store/services/payment-service'
import { mapGetters } from 'vuex'
export default {
  name: 'payment-pay',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,

    CardForm,
    PaymentBanks
  },
  created() {
    this.$store.commit(SET_DEFAULT_PAYMENT_DATA)
  },
  computed: {
    ...mapGetters(['paymentData', 'installment', 'installmentOptions', 'getLocale'])
  },
  methods: {
    showCommission(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(rate.split('%')[0]) + '%'
      )
    },
    showAmount(value) {
      let amount = value >= 0 ? value : 0
      return new Intl.NumberFormat(this.getLocale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(amount)
    }
  }
}
</script>
