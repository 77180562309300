<template>
  <!-- #region Bankalar -->
  <b-row>
    <b-col cols="12">
      <b-card no-body class="w-100">
        <b-card-header @click="open = !open" class="cursor-pointer">
          <h1>{{ $t('payment.banks_information') }}</h1>
          <feather-icon :icon="open ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="30" />
        </b-card-header>
        <b-card-body class="p-0 overflow-auto" v-show="open">
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th colspan="2"></b-th>
                <b-th v-for="count in maxInstallment" :key="count" class="text-nowrap text-right">
                  {{ count == 1 ? $t('card.single_shot') : $t('card.installment', { count }) }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(bank, index) in banks" :key="index">
              <b-tr>
                <b-td rowspan="2" class="bank-div">
                  <b-img :src="bank.image" />
                </b-td>
                <b-td>
                  <span>{{ $t('payment.commission') }}</span>
                </b-td>
                <b-td v-for="count in maxInstallment" :key="'commission_' + count" class="text-nowrap text-right">
                  {{ showCommission(bank, count) }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-nowrap">
                  <span>{{ $t('payment.min_amount') }}</span>
                </b-td>
                <b-td v-for="count in maxInstallment" :key="'min_amount_' + count" class="text-nowrap text-right">
                  <div class="">
                    <span>{{ showAmount(bank, count) }}</span>
                    <div class="d-flex justify-content-end" v-if="checkExtraInstallment(bank, count)">
                      <b-badge variant="light-success" pill>
                        {{ `${$t('card.extra_installment', { count: checkExtraInstallment(bank, count) })}` }}
                      </b-badge>
                    </div>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.bank-div {
  div {
    display: flex;
    flex-direction: column;
  }
}
.table tbody + tbody {
  border-top: none;
}
</style>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BImg, BTableSimple, BBadge, BThead, BTbody, BTh, BTr, BTd } from 'bootstrap-vue'
import { GET_BANKS } from '@/store/services/payment-service'
import { mapGetters } from 'vuex'
import { priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'payment-banks',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BImg,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BBadge
  },
  data() {
    return {
      open: true
    }
  },
  created() {
    this.$store.dispatch(GET_BANKS)
  },
  computed: {
    ...mapGetters(['banks', 'getLocale']),
    bankFields() {
      let temp = [
        {
          key: 'bank',
          label: ''
        }
      ]
      return temp
    },
    installmentHeader() {
      let header = []
      let max = this.maxInstallment
      for (let i = 1; i <= max; i++) {
        for (let j = 0; j < this.banks.length; j++) {
          if (this.banks[j].installments.findIndex(x => x.count == i) !== -1) {
            header.push(i)
            break
          }
        }
      }
      return header
    },
    maxInstallment() {
      let arr = [0]
      this.banks.forEach(item => {
        item.installments.forEach(ins => {
          arr.push(ins.count)
        })
      })
      return Math.max(...arr)
    }
  },
  methods: {
    getInstallment(bank, count) {
      return bank.installments?.find(x => x.count * 1 === count)
    },
    showCommission(bank, count) {
      let ins = this.getInstallment(bank, count)
      if (ins) {
        let comm = ins.commission.replace('%', '') * 1
        return (
          new Intl.NumberFormat(this.getLocale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }).format(comm) + '%'
        )
      } else {
        return '---'
      }
    },
    showAmount(bank, count) {
      let ins = this.getInstallment(bank, count)
      if (ins) {
        let amount = ins.minAmount
        amount = amount ? amount.replace('TL', '').replace(',', '') * 1 : 0
        return priceFormat(amount, this.getLocale, 'TRY')
      } else {
        return '---'
      }
    },
    checkExtraInstallment(bank, count) {
      let temp = this.getInstallment(bank, count)
      if (temp && temp.campaigns?.length) {
        return +temp.campaigns[0].extra_installments
      }
      return 0
    }
  }
}
</script>
