<template>
  <b-card no-body class="w-100">
    <b-card-header>
      <h1>{{ $t('card.title') }}</h1>
    </b-card-header>
    <b-card-body>
      <validation-observer ref="payment" v-slot="{ invalid }">
        <b-row>
          <!-- #region Kart Bilgileri -->
          <b-col cols="12" md="6">
            <b-row>
              <!-- #region Tutar -->
              <b-col cols="12">
                <b-form-group :label="$t('payment.amount')" label-for="payment_amount">
                  <validation-provider #default="{ errors }" :name="$t('payment.amount')" rules="required">
                    <b-input-group>
                      <cleave
                        v-model="amount"
                        :options="amountOptions"
                        class="form-control payment-input"
                        id="payment_amount"
                        :class="{
                          'is-invalid': errors.length > 0
                        }"
                      />
                      <b-input-group-append is-text> TL </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion -->
              <!-- #region Kart Numarası -->
              <b-col cols="12">
                <b-form-group :label="$t('card.cardNumber')" :label-for="fields.cardNumber">
                  <validation-provider #default="{ errors }" :name="$t('card.cardNumber')" :rules="`required|min:${cardNumberMaxLength}|max:${cardNumberMaxLength}`">
                    <b-input-group>
                      <b-form-input
                        type="tel"
                        :id="fields.cardNumber"
                        v-model="cardNumber"
                        @focus="focusCardNumber"
                        @blur="blurCardNumber"
                        class="payment-input"
                        :state="errors.length > 0 ? false : null"
                        :maxlength="cardNumberMaxLength"
                        data-card-field
                        v-number-only
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon :icon="isCardNumberMasked ? 'EyeOffIcon' : 'EyeIcon'" class="cursor-pointer" title="Show/Hide card number" tabindex="-1" :disabled="paymentData.cardNumber === ''" @click="toggleMask" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion -->
              <!-- #region Kart Üzerindeki İsim -->
              <b-col cols="12">
                <b-form-group :label="$t('card.cardName')" :label-for="fields.cardName">
                  <validation-provider #default="{ errors }" :name="$t('card.cardName')" rules="required">
                    <b-form-input type="text" :id="fields.cardName" v-model="cardName" class="payment-input text-uppercase" :state="errors.length > 0 ? false : null" data-card-field v-letter-only autocomplete="off" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion -->
              <!-- #region Kart SK-Ay -->
              <b-col cols="12" md="4">
                <b-form-group :label="$t('card.expirationDate')" :label-for="fields.cardMonth">
                  <validation-provider #default="{ errors }" :name="$t('card.month')" rules="required">
                    <b-form-select class="payment-input" :id="fields.cardMonth" v-model="paymentData.cardMonth" data-card-field :class="errors.length > 0 ? 'is-invalid' : ''">
                      <option value disabled selected>
                        {{ $t('card.month') }}
                      </option>
                      <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">
                        {{ generateMonthValue(n) }}
                      </option>
                    </b-form-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion -->
              <!-- #region Kart SK-Yıl -->
              <b-col cols="12" md="4">
                <b-form-group :label-for="fields.cardYear">
                  <validation-provider #default="{ errors }" :name="$t('card.year')" rules="required">
                    <b-form-select class="payment-input" style="margin-top: 21.39px" :id="fields.cardYear" v-model="paymentData.cardYear" data-card-field :class="errors.length > 0 ? 'is-invalid' : ''">
                      <option value disabled selected>
                        {{ $t('card.year') }}
                      </option>
                      <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                        {{ $index + minCardYear }}
                      </option>
                    </b-form-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion -->
              <!-- #region Kart CVV -->
              <b-col cols="12" md="4">
                <b-form-group :label="$t('card.CVV')" :label-for="fields.cardCvv">
                  <validation-provider #default="{ errors }" :name="$t('card.CVV')" rules="required">
                    <b-form-input type="tel" :id="fields.cardCvv" v-model="cardCvv" class="payment-input" :state="errors.length > 0 ? false : null" maxlength="4" data-card-field autocomplete="off" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion -->
            </b-row>
          </b-col>
          <!-- #endregion -->
          <!-- #region Kart -->
          <b-col cols="12" md="6">
            <Card :fields="fields" :isCardNumberMasked="isCardNumberMasked" />
          </b-col>
          <!-- #endregion -->
          <!-- #region İşlem Açıklaması -->
          <b-col cols="12">
            <b-form-group :label="$t('payment.description')" label-for="description">
              <b-form-textarea id="description" v-model="paymentData.description" :placeholder="$t('payment.description_placeholder')" rows="3" no-resize />
            </b-form-group>
          </b-col>
          <!-- #endregion -->
          <!-- #region Taksit Seçenekleri -->
          <b-col cols="12" v-show="amount > 0 && installmentOptions.length > 0 && cardNumber.length === cardNumberMaxLength" class="overflow-auto">
            <h2>{{ $t('card.installment_title') }}</h2>
            <validation-provider name="selected_installment" rules="required">
              <b-form-radio-group id="selected_installment" v-model="selectedInstallment">
                <table class="table table-striped w-100">
                  <tbody>
                    <tr v-for="(item, index) in installmentOptions" :key="index" class="cursor-pointer" @click="setInstallment(item.count)">
                      <template v-if="checkMinAmount(item)">
                        <td>
                          <div class="d-flex">
                            <b-form-radio :value="item.count" />
                            <span class="text-nowrap" v-if="item.count == 1">
                              {{ $t('card.single_shot') }}
                            </span>
                            <span class="text-nowrap" v-else>
                              {{ $t('card.installment', { count: item.count }) }}
                            </span>
                          </div>
                        </td>
                        <td v-if="anyExtraInstallments">
                          <div class="d-flex" v-if="item.campaigns.length > 0">
                            <b-badge variant="light-success" pill>
                              {{
                                $t('card.extra_installment', {
                                  count: item.campaigns[0].extra_installments
                                })
                              }}
                            </b-badge>
                          </div>
                        </td>
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                              flex-column
                            "
                          >
                            <span class="text-nowrap">
                              {{ showCommission(item.commission) }}
                            </span>
                            <span class="text-muted">
                              {{ $t('card.commission') }}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                              flex-column
                            "
                          >
                            <span class="text-nowrap">
                              {{ installmentInfo(item.count, item.commission, item.campaigns) }}
                            </span>
                            <span class="text-muted">
                              {{ item.count == 1 ? $t('card.single_shot') : $t('card.monthly_payment') }}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                              flex-column
                            "
                          >
                            <span class="text-nowrap"> {{ amountInfo(item.commission) }}</span>
                            <span class="text-muted">
                              {{ $t('card.total_payment') }}
                            </span>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </b-form-radio-group>
            </validation-provider>
          </b-col>
          <!-- #endregion -->
          <!-- #region Ödeme Yap -->
          <b-col cols="12" @mouseenter="checkForm()" class="mt-1">
            <b-button @click="pay()" :disabled="invalid || installment === null" variant="primary" block>
              {{ $t('payment.pay_button_text') }}
            </b-button>
          </b-col>
          <!-- #endregion -->
          <!-- #region Ödeme Modal -->
          <b-modal ref="paymentModal" size="md" no-close-on-backdrop no-close-on-esc hide-header hide-footer centered>
            <b-card-text class="text-center">
              <span v-html="$t('payment.on_redirect')" />
              <br />
              <b-spinner class="mr-1 mt-1" variant="primary" />
            </b-card-text>
            <div id="modalContent" class="d-none" />
          </b-modal>
          <!-- #endregion -->
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import $ from 'jquery'
import Card from './Card.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormRadioGroup,
  BFormRadio,
  BBadge,
  BButton,
  BModal,
  BCardText,
  BSpinner
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import { CHANGE_CARD_NUMBER, MASK_CARD_NUMBER, SET_CARD_CVV, SET_CARD_MONTH, SET_CARD_NAME, SET_CARD_YEAR, UNMASK_CARD_NUMBER, SET_AMOUNT, SET_INSTALLMENT, PAYMENT_SALE } from '@/store/services/payment-service'
import { priceFormat } from '@/assets/js/functions/currency-functions'
export default {
  name: 'CardForm',
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BButton,
    BModal,
    BCardText,
    BSpinner,

    Cleave,

    ValidationObserver,
    ValidationProvider,

    Card
  },
  data() {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      cardNumberMaxLength: 19,
      required
    }
  },
  computed: {
    ...mapGetters(['paymentData', 'installment', 'installmentOptions', 'getLocale']),
    amount: {
      get() {
        return this.paymentData.amount
      },
      set(val) {
        this.$store.commit(SET_AMOUNT, val)
      }
    },
    cardNumber: {
      get() {
        return this.paymentData.cardNumber
      },
      set(val) {
        this.$store.dispatch(CHANGE_CARD_NUMBER, val).then(response => {
          this.cardNumberMaxLength = response
        })
      }
    },
    cardName: {
      get() {
        return this.paymentData.cardName
      },
      set(val) {
        this.$store.commit(SET_CARD_NAME, val)
      }
    },
    cardYear: {
      get() {
        return this.paymentData.cardYear
      },
      set(val) {
        this.$store.commit(SET_CARD_YEAR, {
          cardYear: val,
          minCardMonth: this.minCardMonth
        })
      }
    },
    cardMonth: {
      get() {
        return this.paymentData.cardMonth
      },
      set(val) {
        this.$store.commit(SET_CARD_MONTH, val)
      }
    },
    cardCvv: {
      get() {
        return this.paymentData.cardCvv
      },
      set(val) {
        this.$store.commit(SET_CARD_CVV, val)
      }
    },
    selectedInstallment: {
      get() {
        return this.installment
      },
      set(val) {
        this.setInstallment(val)
      }
    },
    minCardMonth() {
      if (this.paymentData.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    },
    backgroundImage() {
      let rand = Math.floor(Math.random() * 25) + 1
      return require(`@/assets/images/credit-card/${rand}.jpeg`)
    },
    anyExtraInstallments() {
      if (this.installmentOptions.findIndex(x => x.campaigns.length > 0) !== -1) {
        return true
      }
    },
    amountOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  mounted() {
    this.maskCardNumber()
  },
  methods: {
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n
    },
    invaildCard() {
      let number = this.paymentData.cardNumberNotMask.replace(/ /g, '')
      var sum = 0
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1))
        if (i % 2 === 0) {
          intVal *= 2
          if (intVal > 9) {
            intVal = 1 + (intVal % 10)
          }
        }
        sum += intVal
      }
      if (sum % 10 !== 0) {
        alert(this.$t('cardForm.invalidCardNumber'))
      }
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      }
    },
    maskCardNumber() {
      this.$store.commit(MASK_CARD_NUMBER)
    },
    unMaskCardNumber() {
      this.$store.commit(UNMASK_CARD_NUMBER)
    },
    focusCardNumber() {
      this.unMaskCardNumber()
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    },
    setInstallment(val) {
      this.$store.commit(SET_INSTALLMENT, val)
    },
    showCommission(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(rate.split('%')[0]) + '%'
      )
    },
    installmentInfo(count, cms, campaigns) {
      let tempCount = +count
      if (campaigns?.length && campaigns[0]?.extra_installments) {
        tempCount += +campaigns[0].extra_installments
      }
      let comission = cms.split('%')[0]
      let amount = this.paymentData.amount > 0 ? this.paymentData.amount * 1 + (this.paymentData.amount * comission) / 100 : 0
      let monthly = amount > 0 ? (amount * 1) / tempCount : 0
      return priceFormat(monthly, this.getLocale, 'TRY')
    },
    amountInfo(cms) {
      let comission = cms.split('%')[0]
      let amount = this.paymentData.amount > 0 ? this.paymentData.amount * 1 + (this.paymentData.amount * comission) / 100 : 0
      return priceFormat(amount, this.getLocale, 'TRY')
    },
    checkForm() {
      this.$refs.payment.validate()
    },
    checkMinAmount(ins) {
      let minAmount = ins?.minAmount
      if (minAmount === null || minAmount === undefined) {
        minAmount = 0
      } else {
        minAmount = minAmount.replace('TL', '').replace(',', '') * 1
      }
      return this.amount * 1 >= minAmount
    },
    pay() {
      this.$refs.paymentModal.show()
      this.$store
        .dispatch(PAYMENT_SALE)
        .then(response => {
          $('#modalContent').html(response)
        })
        .catch(err => {
          this.$refs.paymentModal.hide()
          this.$swal({
            title: this.$t('payment.error_title'),
            text: err.ErrorMSG,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            confirmButtonText: this.$t('payment.error_button_text'),
            buttonsStyling: false
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/credit-card.scss';

.payment-modal {
  .modal-body {
    background: white !important;
    color: black !important;
    border-radius: 24px;
  }
}
</style>

<style lang="scss" scoped>
.payment-input {
  height: 50px;
  font-size: 18px;
}
</style>
